import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgIconComponent } from '@dmb/core';
import { CoBrandingService } from '../../services';

@Component({
  selector: 'dmb-brand-logo',
  standalone: true,
  imports: [CommonModule, SvgIconComponent],
  templateUrl: './brand-logo.component.html',
  styleUrl: './brand-logo.component.scss',
})
export class BrandLogoComponent {
  logo = this.coBrandService.loginLogo;
  coBrand = this.coBrandService.coBrand;

  constructor(private coBrandService: CoBrandingService) {}
}
