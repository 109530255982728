import {AsyncPipe, NgIf} from '@angular/common';
import {Component, HostBinding, HostListener, OnDestroy} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {Subscription, takeLast} from 'rxjs';
import {distinctUntilChanged, filter, map, mergeMap, tap} from 'rxjs/operators';
import {FcmService, UserService} from '../../user';
import {SpinnerComponent} from '../form/spinner/spinner.component';
import {LoginComponent} from '../login';
import {CombinedMenuComponent, MenuService} from './combined-menu';

@Component({
  selector: 'dmb-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  standalone: true,
  imports: [LoginComponent, AsyncPipe, NgIf, RouterOutlet, SpinnerComponent, CombinedMenuComponent],
})
export class MainComponent implements OnDestroy{
  subscriptions = new Subscription();

  @HostBinding('class.collapsed') collapsed = false;
  showLogin = this.userService.user.pipe(
    map((u) => u?.authenticated === false));

  firebaseRegistration = this.userService.user.pipe(
    filter(u => u.authenticated === true),
    mergeMap(() => this.firebaseService.firebaseRegistration)
  ).subscribe();


  constructor(
    private userService: UserService,
    private menuService: MenuService,
    private firebaseService: FcmService
  ) {
    this.subscriptions.add(this.menuService.mainMenuCollapsed.subscribe((v) => (this.collapsed = v)));
  }

  @HostListener('click') disableActivated() {
    this.menuService.setActive(null);
  }

  ngOnDestroy(): void {
    this.firebaseRegistration.unsubscribe();
  }
}
