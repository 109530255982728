import {inject, Injectable} from "@angular/core";
import {getToken, Messaging, onMessage} from "@angular/fire/messaging";
import {HttpClient} from "@angular/common/http";
import {pipe} from "fp-ts/function";
import {combineLatest, forkJoin, from, mergeAll, mergeMap, Observable, of, takeLast} from "rxjs";
import {filter, map, switchMap, tap} from "rxjs/operators";
import {AuthData} from "../model/user/login";
import {environment} from "../../../../../apps/dmb-web/src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class FcmService {
  private msg = inject(Messaging);
  private registerTokenUrl = environment.apiV2 + "/notifications/register-device";


  /* If notifications are enabled do request the user to grant permission of browser.
     If permmission is granted register the service worker, get a token from firebase
     and register this token. the token will only be fetched and registered once for each browser.
     If fetched once it marked so in the local storage.
  * */
  firebaseRegistration: Observable<boolean> = pipe(this.http.post(`/api/v4/public/users/auth_for_token`, null) as Observable<AuthData>,
    filter((authData) => authData.user?.notification_enabled === true),
    switchMap((authData) => {
      return pipe(
        from(Notification.requestPermission()),
        filter((permission) => permission === "granted"),
        switchMap(granted => from(navigator.serviceWorker
          .register("/assets/firebase-messaging-sw.js", {
            type: "module",
          }))),
        tap((serviceWorkerRegistration) => {
          onMessage(this.msg, function (payload) {
            let body = payload.notification?.body;
            if (payload.data?.['downloadLink']) body = body + "\nDownload here " + payload.data?.['downloadLink'];
            let icon = payload.data?.['image'] || "/assets/favicons/dmb.dev.ico";
            const notificationOptions = {
              body: body,
              icon: icon
            };
            let title: string = payload.notification?.title !== undefined ? payload.notification.title : "";
            serviceWorkerRegistration.showNotification(title, notificationOptions);
            console.log("My Firebase Cloud Message", payload, notificationOptions);
          })
        }),
        filter((serviceWorkerRegistration)=>
              localStorage.getItem('firebaseTokenRegistered') === null),
        mergeMap(serviceWorkerRegistration => from(getToken(this.msg, {
          serviceWorkerRegistration: serviceWorkerRegistration as ServiceWorkerRegistration,
        }))),
        mergeMap((firebasetoken) => this.http.post(this.registerTokenUrl,
          {
            firebaseToken: firebasetoken,
            deviceType: "web",
          }, {headers: {"Authorization": (authData as AuthData).access_token}})),
        mergeMap(a =>  {
          localStorage.setItem('firebaseTokenRegistered', "true");
          return of(true);
        })
      )
    }));

  constructor(private http: HttpClient) {
  }
}
