import { AsyncPipe, NgIf } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router, UrlSerializer } from '@angular/router';
import { validatorWrapper } from '@dmb/core';
import { firstValueFrom } from 'rxjs';
import { map, startWith, take } from 'rxjs/operators';
import { toggleBackgroundBlur } from '../../helper/modal.helper';
import { buildV3Url, isNonAngularUrl } from '../../helper/url.helper';
import { LoadingService } from '../../services';
import { UserService } from '../../user';
import { BrandLogoComponent } from '../brand-logo/brand-logo.component';
import { ButtonComponent, InputComponent, SpinnerComponent } from '../form';
import { ResetPasswordComponent } from '../modal';
import { ModalService } from '../modal/modal.service';
import { LoginErrorsComponent } from './login-errors/login-errors.component';
import { QrCodeComponent } from './qr-code/qr-code.component';

export type Login401ResponseStatus = {
  needsTotpCode?: boolean;
  error?: string;
  totpQRCode?: string;
  secret?: string;
  id?: number;
};

@Component({
  selector: 'dmb-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    InputComponent,
    AsyncPipe,
    LoginErrorsComponent,
    QrCodeComponent,
    NgIf,
    ButtonComponent,
    SpinnerComponent,
    BrandLogoComponent,
  ],
})
export class LoginComponent {
  @ViewChild('username', { read: ElementRef }) username: ElementRef<HTMLElement> | undefined;
  @ViewChild('password', { read: ElementRef }) password: ElementRef<HTMLElement> | undefined;
  errorStatus = 0;
  errorMessage = '';
  image: SafeUrl = {};
  isLoggedIn = this.userService.authenticatedUser.pipe(
    map(() => true),
    startWith(false),
  );
  realName = this.userService.user.pipe(map((user) => user.fullName));
  showQRCode = false;
  showTotpField = false;
  resetPasswordComponent = ResetPasswordComponent;
  formValid = false;

  user = new FormControl<string>('', [Validators.required]);
  pass = new FormControl<string>('', [Validators.required]);
  totp = new FormControl<string>('', [
    validatorWrapper(Validators.pattern(/[0-9]{6}/), () => ({
      totp: $localize`:@@login.error.totp-invalid:Must be 6 digits`,
    })),
  ]);

  webAuthFormGroup = this.fb.nonNullable.group({
    user: this.user,
    pass: this.pass,
    totp: this.totp,
    browserFingerprint: new FormControl<string>(''),
    withException: new FormControl<boolean>(false),
    respectTotpRequirement: new FormControl<boolean>(true),
  });

  get redirectTo() {
    return this.route.snapshot.queryParams['comefrom'];
  }

  constructor(
    private userService: UserService,
    private fb: FormBuilder,
    public modalService: ModalService,
    private route: ActivatedRoute,
    private router: Router,
    private urlSerializer: UrlSerializer,
    public loading: LoadingService,
  ) {}

  passwordLost() {
    const modal = this.modalService.openDialog<ResetPasswordComponent>(this.resetPasswordComponent);
    modal.closed.pipe(take(1)).subscribe(() => toggleBackgroundBlur());
  }

  async onSubmit($event: Event): Promise<void> {
    $event.preventDefault();
    const login = this.webAuthFormGroup.value;
    if (!(login.user && login.pass)) return;

    try {
      this.loading.setLoading(true);
      const res = await firstValueFrom(this.userService.authenticateWithFingerPrint(this.webAuthFormGroup));
      await firstValueFrom(this.userService.authenticatedUser);
      this.loading.setLoading(false);
      const redirUrl = res.redirectTo ? res.redirectTo : this.redirectTo;
      if (redirUrl) await this.router.navigate([isNonAngularUrl(redirUrl) ? buildV3Url(redirUrl) : redirUrl]);
    } catch (err) {
      this.loading.setLoading(false);
      if (err instanceof HttpErrorResponse) await this.handleLoginError(err);
      else throw err;
    }
  }

  public setWindowLocationHref(href: string) {
    window.location.href = href;
  }

  // this should be handled by the service.
  private async handleLoginError(err: HttpErrorResponse) {
    if (!err.message) this.errorStatus = 1;

    if (err.status === 401) {
      const errorStatusMessage: Login401ResponseStatus = err.error;

      this.errorMessage = errorStatusMessage.error || '';
      this.showTotpField = !!errorStatusMessage.needsTotpCode;

      if (errorStatusMessage.totpQRCode) {
        this.image = errorStatusMessage.totpQRCode;
        this.showQRCode = true;
      }
      this.errorStatus = errorStatusMessage.error ? err.status : 0;
      if (errorStatusMessage.error === 'error.login.password-expired') {
        const url = '/index.php';
        const urlTree = this.urlSerializer.parse(url);

        const { error, ...qp } = errorStatusMessage;
        urlTree.queryParams = { ...qp, page: 'password-reset' };

        /**
         * @todo this is a short term hack to make redirection work locally as well as when deployed.
         * when the password reset dialog is moved to angular this should disappear.
         */
        return setTimeout(
          () =>
            (window.location.href = `${window.location.protocol}//${window.location.hostname}${urlTree.toString()}`),
          2000,
        );
      }
      return false;
    }
    this.errorStatus = err.status;
    return true;
  }
}
