import { DmbWebConfig } from './dmb-web-config';

export const environment: DmbWebConfig = {
  apiV2: "https://api.dev.kontornewmedia.com/api/v2",
  stage: 'staging',
  production: false,
  sentryDsn: 'https://bec91b6e43a0d4a4dfdfdc5e0c5de244@o4507407783821312.ingest.de.sentry.io/4508086013526096',
  firebaseConfig: {
    apiKey: "AIzaSyCezlcrHRO4AfVzmr-XiV6aDMGqF3clpq8",
    authDomain: "knm-dmb-firebase-dev.firebaseapp.com",
    projectId: "knm-dmb-firebase-dev",
    storageBucket: "knm-dmb-firebase-dev.firebasestorage.app",
    messagingSenderId: "986173583846",
    appId: "1:986173583846:web:8af6479be4c582b20d2627"
  }
};
