<dmb-spinner></dmb-spinner>
<div class="login">
  <dmb-brand-logo></dmb-brand-logo>

  <div *ngIf="(isLoggedIn | async) === false" class="form">
    <form (ngSubmit)="onSubmit($event)" [formGroup]="webAuthFormGroup" id="loginForm">
      <dmb-input
        #username
        [control]="user"
        [required]="true"
        [showAsterisk]="false"
        i18n-label="@@login.label.username"
        id="user"
        label="Username"
      ></dmb-input>

      <dmb-input
        #password
        [control]="pass"
        [required]="true"
        [showAsterisk]="false"
        i18n-label="@@login.label.password"
        id="pass"
        label="Password"
        type="password"
      ></dmb-input>

      <dmb-qr-code *ngIf="showQRCode" [qrCode]="image"></dmb-qr-code>

      <dmb-input
        *ngIf="showTotpField"
        [control]="totp"
        i18n-label="@@login.label.totp.placeholder"
        id="totp"
        label="6-digit Authentication Code"
      ></dmb-input>
      <dmb-login-errors *ngIf="errorStatus" [message]="errorMessage" [status]="errorStatus"></dmb-login-errors>

      <dmb-button color="primary" design="raised" fullWidth="true" i18n="@@login.button.login" type="submit"
        >Submit
      </dmb-button>

      <dmb-button
        (click)="passwordLost()"
        *ngIf="!showTotpField"
        design="flat"
        fullWidth="true"
        i18n="@@login.button.password-lost"
        type="button"
        >Forgot Password?
      </dmb-button>
    </form>
  </div>
  <div *ngIf="(isLoggedIn | async) === true" class="form" i18n="@@dmb-web.login.already-logged-in">
    You are logged in as {{ realName | async }}
  </div>
</div>
