import { registerLocaleData } from '@angular/common';
import { Injectable } from '@angular/core';
import { loadTranslations } from '@angular/localize';
import { DmbLanguage, isDmbLanguage } from './model';

/**
 * this is a workaround for the standard Angular behavior of compiling each
 * translation and accessing them through different urls.
 * In this case we read the desired language from the browser or local storage
 * on app init and load the translations from there. When the user is logged in
 * their preferred language will be loaded and set in localstorage and the
 * app will reload to ensure the translations are loaded.
 */
@Injectable({
  providedIn: 'root',
})
export class I18n {
  private _locale: DmbLanguage | null = null;

  get locale(): DmbLanguage {
    return this._locale || this.fromStorage();
  }
  private defaultLocale = isDmbLanguage(navigator.language) ? navigator.language : 'en';

  private fromStorage(): DmbLanguage {
    try {
      const l = localStorage?.getItem('locale')?.toLowerCase();
      return isDmbLanguage(l) ? l : this.defaultLocale;
    } catch {
      return this.defaultLocale;
    }
  }

  async init() {
    return this.setLocale(this.locale);
  }

  /**
   *
   * WARNING: it is possible to disable localstorage and to prevent the
   * continual reloading of the app we will return a boolean value only when
   * the language changes and is correctly written to local storage.
   * this will mean that the application will only be in the default
   * language of the browser.
   */
  async setLocale(newLocale: DmbLanguage): Promise<boolean> {
    if (newLocale === this._locale) return false;
    this._locale = newLocale || this.fromStorage() || this.defaultLocale;

    try {
      localStorage.setItem('locale', this._locale.toLowerCase());
    } catch {
      console.error('local storage is disabled');
    }

    // Use web pack magic string to only include required locale data
    const localeModule = await import(
      /* webpackInclude: /(de|en|fr)\.mjs$/ */
      `../../../../node_modules/@angular/common/locales/${this.locale}.mjs`
    );

    // Set locale for built in pipes, etc.
    registerLocaleData(localeModule.default);

    // Load translation file
    const localeTranslationsModule = await import(`../../../../apps/dmb-web/src/assets/i18n/${this.locale}.json`);

    // Load translations for the current locale at run-time
    loadTranslations(localeTranslationsModule.default.translations);

    /**
     * Normal behavior is to reload the app when the language changes and the updated language is stored in
     * localstorage so that we do not continually reload
     * it is possible that local storage is disabled so to prevent constant reloads we return a boolean
     * indicating that the app language has been updated and stored in localstore
     */

    return newLocale === localStorage.getItem('locale');
  }
}
